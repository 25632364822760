import clsx from "clsx";
import InfoBox from "components/reusable/InfoBox/InfoBox";
import LogoContainer from "components/reusable/LogoContainer/LogoContainer";
import Popup from "components/reusable/Popup/Popup";
import WaitingView from "components/reusable/WaitingView/WaitingView";
import { useDefaultStyles } from "components/reusable/defaultStyles";
import useCheckoutProvider from "contexts/CheckoutContext";
import React, { useEffect, useMemo, useState } from "react";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import { CHECKOUT_SUMMARY_PATH } from "routing/paths";
import { REGEX_SORT_CODE } from "shared/constants/Regex";
import Http from "utils/Http";
import { getCheckoutPageLink, getCheckoutPath } from "utils/PayoutUtils";
import { CheckoutRouteProps } from "utils/types/Routes";
import CheckoutLayout from "../../components/reusable/Layout/CheckoutLayout";
import PageHeader from "../../components/reusable/Layout/PageHeader";
import useStyles from "./styles";

const CheckoutBankDetails: React.FC = () => {
  const defaultClasses = useDefaultStyles();
  const classes = useStyles();
  const navigate = useNavigate();
  const { state } = useLocation();
  const { payout, bankAccount, setBankAccount } = useCheckoutProvider();

  const [hasError, setHasError] = useState(false);
  const [showPopup, setShowPopup] = useState(false);
  const [loading, setLoading] = useState(true);

  const { payoutId, token } = state as CheckoutRouteProps;

  const onContinue = async () => {
    navigate(getCheckoutPageLink(CHECKOUT_SUMMARY_PATH), { state: { payoutId: payoutId!, token } });
  };

  const getBankDetails = async () => {
    try {
      setLoading(true);
      const { data } = await Http.post(`checkout/verify-token`, {
        payoutId,
        token,
      });
      setBankAccount(data.bankDetails);
      setLoading(false);
    } catch (error) {
      setHasError(true);
      setLoading(false);
    }
  };

  useEffect(() => {
    if (payoutId && token) {
      getBankDetails();
    }
  }, [payoutId, token]);

  const renderBankAccount = useMemo(() => {
    if (bankAccount?.creditPartyIdentifier.match(REGEX_SORT_CODE)) {
      return (
        <div className={classes.accountBox}>
          <div className={clsx(classes.inputContainer, classes.sortCode)}>
            <span>Sort Code</span>
            <div className={classes.inputBox}>{bankAccount?.creditPartyIdentifier.split(" ")[0].trim()}</div>
          </div>
          <div className={classes.inputContainer}>
            <span>Account Number</span>
            <div className={classes.inputBox}>{bankAccount?.creditPartyIdentifier.split(" ")[1].trim()}</div>
          </div>
        </div>
      );
    } else {
      return (
        <div className={classes.accountBox}>
          <div className={classes.inputContainer}>
            <span>Account Number</span>
            <div className={classes.inputBox}>{bankAccount?.creditPartyIdentifier}</div>
          </div>
        </div>
      );
    }
  }, [bankAccount]);

  if (loading) return <WaitingView lottieColor="primary" />;
  if (hasError) return <InfoBox message="Error verifying token" subMessage={<h5>It may be due to token been invalid or missing</h5>} />;

  if (!state) {
    return <Navigate to={getCheckoutPath()} />;
  }

  return (
    <CheckoutLayout
      leftContent={
        <div className={defaultClasses.leftContentContainer}>
          <PageHeader title="Verified!" className={defaultClasses.textPrimary} />
          <p className={clsx(defaultClasses.mb40, defaultClasses.maxWidth)}>
            <b>Hi {bankAccount?.firstName}</b> 👋 , thanks for verifying. Your payment will be made into the account shown:
          </p>
          <div className={defaultClasses.showOnDesktop}>
            <LogoContainer />
          </div>
        </div>
      }
      rightContent={
        <div className={defaultClasses.rightContentContainer}>
          {renderBankAccount}

          <div onClick={() => setShowPopup(true)} className={clsx(defaultClasses.textButton, defaultClasses.textCenter)}>
            Wrong bank details?
          </div>
          <Popup open={showPopup} handleClose={() => setShowPopup(false)}>
            <>
              Bank details have been provided by {payout.partner.name}.<br />
              <br />
              If you wish to change any information please contact {payout.partner.name} directly.
            </>
          </Popup>

          <button onClick={onContinue} className={clsx(defaultClasses.button)}>
            Continue
          </button>
        </div>
      }
    />
  );
};

export default CheckoutBankDetails;
